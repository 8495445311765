import { useAuth0 } from '@auth0/auth0-vue'
import { H3Error } from 'h3'

type UserMe = { user?: SerializedUserSchema<SerializedUserSchema> } & Partial<Pick<SerializedUserSchema<SerializedUserSchema>, 'userId' | 'displayName' | 'avatarUrl' | 'rank' | 'country' | 'city' | 'birthday' | 'webpage' | 'selfIntroduction' | 'donor' | 'followee' | 'follower' | 'createdAt' | 'updatedAt' | 'selectedUser'>>

export function useUser () {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const snackbar = useSnackbar()

  const state = useState<UserMe>('me', () => {
    return {
      user: undefined,
      userId: '',
      displayName: '',
      avatarUrl: '',
      rank: '-',
      country: '-',
      city: '',
      birthday: '',
      webpage: '',
      selfIntroduction: '',
      donor: {
        donated: false,
        visible: false
      },
      follower: [],
      followee: [],
      createdAt: '',
      updatedAt: '',
      selectedUser: undefined,
      managers: []
    }
  })

  const managersState = useState<SerializedUserSchema[]>('managers', () => {
    return []
  })

  const update = async () => {
    const token = await getAccessTokenSilently()
    const response = await $fetch<{ user: SerializedUserSchema<SerializedUserSchema> }>(
      '/api/me',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        },
        credentials: 'same-origin'
      }
    )

    const user = response.user.selectedUser || response.user
    state.value.user = response.user
    state.value.userId = user?.userId
    state.value.displayName = user?.displayName
    state.value.avatarUrl = user?.avatarUrl
    state.value.rank = user?.rank
    state.value.country = user?.country
    state.value.city = user?.city
    state.value.birthday = user?.birthday
    state.value.webpage = user?.webpage
    state.value.selfIntroduction = user?.selfIntroduction
    state.value.donor = user?.donor
    state.value.follower = user?.follower
    state.value.followee = user?.followee
    state.value.selectedUser = response.user?.selectedUser
    state.value.createdAt = user?.createdAt
    state.value.updatedAt = user?.updatedAt

    const responseManagers = await $fetch<{ docs:(SerializedUserSchema)[], page: number, pages: number, total: number }>(
      '/api/user', {
      query: {
        manager: response.user?._id,
        page: '1',
        limit: '10'
      }
    })
    managersState.value = responseManagers.docs
  }

  const changeSelectedUser = async (selectedUser: string) => {
    const token = await getAccessTokenSilently()
    await $fetch(
      '/api/me/selectedUser',
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token
        },
        credentials: 'same-origin',
        body: {
          selectedUser
        }
      }
    ).catch((error: H3Error<{ message: string }>) => {
      snackbar.showMessage(error.data?.message || '', 'error')

      throw error
    })
    reloadNuxtApp()
  }

  const clearSelectedUser = async () => {
    const token = await getAccessTokenSilently()
    await $fetch(
      '/api/me/selectedUser',
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + token
        },
        credentials: 'same-origin'
      }
    ).catch((error: H3Error<{ message: string }>) => {
      snackbar.showMessage(error.data?.message || '', 'error')

      throw error
    })
    reloadNuxtApp()
  }

  watch(isAuthenticated, async (value) => {
    if (value !== true && value === isAuthenticated.value) { return }
    await update()
  })

  return {
    ...toRefs(state.value),
    managers: managersState,
    update,
    changeSelectedUser,
    clearSelectedUser
  }
}
