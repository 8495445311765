import { default as document_45drivensPAgb3VykSMeta } from "/home/nobuhiro/developments/v2.renjuportal/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue?macro=true";
import { default as _91_46_46_46slug_9326kpj9zkrYMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/blog/[...slug].vue?macro=true";
import { default as indexPBQ57nBxoLMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/blog/index.vue?macro=true";
import { default as imagedtDAgiCvJqMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/board/image.vue?macro=true";
import { default as indexDyD51SdRJDMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/board/index.vue?macro=true";
import { default as twitter_45thumbnailRPr7loA4vCMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/board/twitter-thumbnail.vue?macro=true";
import { default as _91id_937VRzTdZkptMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/game/[id].vue?macro=true";
import { default as indexwfEDnoMyI4Meta } from "/home/nobuhiro/developments/v2.renjuportal/pages/game/index.vue?macro=true";
import { default as _91slug_93QIK1aAyr8tMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/group/[slug].vue?macro=true";
import { default as index4po7u9tzEEMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/group/index.vue?macro=true";
import { default as index43sVvBnO6bMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/index.vue?macro=true";
import { default as _91number_93aIpR32QfLOMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/live/[number].vue?macro=true";
import { default as boardCm34Esx25BMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/me/board.vue?macro=true";
import { default as gamea4TZOifm9uMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/me/game.vue?macro=true";
import { default as noteHrilPICnArMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/me/note.vue?macro=true";
import { default as puzzleM8z57991orMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/me/puzzle.vue?macro=true";
import { default as scoreR7aAcpTq0nMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/me/score.vue?macro=true";
import { default as editsTc8yUR4vLMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/note/[id]/edit.vue?macro=true";
import { default as indexyY2Nfat2JfMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/note/[id]/index.vue?macro=true";
import { default as indexgm0E8F2G6cMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/note/index.vue?macro=true";
import { default as list6N3QIiofxWMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/note/list.vue?macro=true";
import { default as editVXBF3yXXgkMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/[id]/edit.vue?macro=true";
import { default as indexlEekev1ykPMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/[id]/index.vue?macro=true";
import { default as indexQ4kWoub8aqMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/index.vue?macro=true";
import { default as listx0cBPR1MNGMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/list.vue?macro=true";
import { default as editpLmHhLhOJbMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/score/[id]/edit.vue?macro=true";
import { default as indexgAgEVC62kFMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/score/[id]/index.vue?macro=true";
import { default as indexv0yc3hPIZAMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/score/index.vue?macro=true";
import { default as list4lLMeBcPOCMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/score/list.vue?macro=true";
import { default as indexY9D5VTODQYMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/search/index.vue?macro=true";
import { default as indexcbAgPF8aIhMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/settings/index.vue?macro=true";
import { default as _91id_93HH52zAXsHkMeta } from "/home/nobuhiro/developments/v2.renjuportal/pages/user/[id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/node_modules/@nuxt/content/dist/runtime/pages/document-driven.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "board-image",
    path: "/board/image",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/board/image.vue").then(m => m.default || m)
  },
  {
    name: "board",
    path: "/board",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/board/index.vue").then(m => m.default || m)
  },
  {
    name: "board-twitter-thumbnail",
    path: "/board/twitter-thumbnail",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/board/twitter-thumbnail.vue").then(m => m.default || m)
  },
  {
    name: "game-id",
    path: "/game/:id()",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/game/[id].vue").then(m => m.default || m)
  },
  {
    name: "game",
    path: "/game",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/game/index.vue").then(m => m.default || m)
  },
  {
    name: "group-slug",
    path: "/group/:slug()",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/group/[slug].vue").then(m => m.default || m)
  },
  {
    name: "group",
    path: "/group",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/group/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "live-number",
    path: "/live/:number()",
    meta: _91number_93aIpR32QfLOMeta || {},
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/live/[number].vue").then(m => m.default || m)
  },
  {
    name: "me-board",
    path: "/me/board",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/me/board.vue").then(m => m.default || m)
  },
  {
    name: "me-game",
    path: "/me/game",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/me/game.vue").then(m => m.default || m)
  },
  {
    name: "me-note",
    path: "/me/note",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/me/note.vue").then(m => m.default || m)
  },
  {
    name: "me-puzzle",
    path: "/me/puzzle",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/me/puzzle.vue").then(m => m.default || m)
  },
  {
    name: "me-score",
    path: "/me/score",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/me/score.vue").then(m => m.default || m)
  },
  {
    name: "note-id-edit",
    path: "/note/:id()/edit",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/note/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "note-id",
    path: "/note/:id()",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/note/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "note",
    path: "/note",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/note/index.vue").then(m => m.default || m)
  },
  {
    name: "note-list",
    path: "/note/list",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/note/list.vue").then(m => m.default || m)
  },
  {
    name: "puzzle-id-edit",
    path: "/puzzle/:id()/edit",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "puzzle-id",
    path: "/puzzle/:id()",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "puzzle",
    path: "/puzzle",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/index.vue").then(m => m.default || m)
  },
  {
    name: "puzzle-list",
    path: "/puzzle/list",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/puzzle/list.vue").then(m => m.default || m)
  },
  {
    name: "score-id-edit",
    path: "/score/:id()/edit",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/score/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "score-id",
    path: "/score/:id()",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/score/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "score",
    path: "/score",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/score/index.vue").then(m => m.default || m)
  },
  {
    name: "score-list",
    path: "/score/list",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/score/list.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id",
    path: "/user/:id()",
    component: () => import("/home/nobuhiro/developments/v2.renjuportal/pages/user/[id].vue").then(m => m.default || m)
  }
]