export function useSnackbar () {
  const state = useState('snackbar', () => {
    return {
      open: false,
      message: '',
      color: 'success'
    }
  })

  const showMessage = (message: string, color: string = 'success') => {
    state.value.open = true
    state.value.message = message
    state.value.color = color
  }

  return {
    ...toRefs(state.value),
    showMessage
  }
}
