import { type ThemeDefinition, createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VDateInput } from 'vuetify/labs/VDateInput'
import '@mdi/font/css/materialdesignicons.css'

export default defineNuxtPlugin((nuxtApp) => {
  const customTheme: ThemeDefinition = {
    dark: false,
    colors: {
      primary: '#000000',
      secondary: '#A8E6C4',
      'secondary-dark': '#4AB078',
      'secondary-light': '#CAE6D7',
      caution: '#f9de4f'
    }
  }

  const vuetify = createVuetify({
    ssr: true,
    components: {
      ...components,
      VDateInput
    },
    directives,
    theme: {
      defaultTheme: 'customTheme',
      themes: {
        customTheme
      }
    }
  })

  nuxtApp.vueApp.use(vuetify)
})
