import { io, Socket } from 'socket.io-client'
import { useAuth0 } from '@auth0/auth0-vue'
import { type ScoreClientToServerEvents, type ScoreServerToClientEvents, type GameClientToServerEvents, type GameServerToClientEvents } from '../types/socket'

export default defineNuxtPlugin((_nuxtApp) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const scoreIO: Socket<ScoreClientToServerEvents, ScoreServerToClientEvents> = io('/score')
  const gameIO: Socket<GameServerToClientEvents, GameClientToServerEvents> = io('/game', { autoConnect: false }).disconnect()

  watch(isAuthenticated, async (value) => {
    if (!value) { return }
    const token = await getAccessTokenSilently()
    gameIO.auth = { token }
    gameIO.connect()
  })

  return {
    provide: {
      scoreIO,
      gameIO
    }
  }
})
